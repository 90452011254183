import moment from 'moment';
import AdminActionTypes, { AdminAction } from './adminActionTypes';
import AdminState from './adminState';

const initialAdminState: AdminState = {
    isAuth: false,
    adminUser: null,
    hasWeakPassword: false,
    appClients: [],
    notification: null,
    adminClient: null,
    user: null,
    clientListFilter: { id: 'all', name: 'All' },
    startDateFilter: moment().subtract(moment().hour(), 'hours').toISOString(),
    endDateFilter: new Date().toISOString(),
    showPdfFrameworkDialog: false,
    currentDecisionTree: null,
    currentRegion: '',
};

const adminReducer = (
    state = initialAdminState,
    action: AdminAction,
): AdminState => {
    const { type, payload } = action;
    switch (type) {
        case AdminActionTypes.SET_ADMIN_AUTH: {
            return {
                ...state,
                adminUser: payload.adminUser,
                isAuth: payload.isAuth,
            };
        }
        case AdminActionTypes.SET_HAS_WEAK_PASSWORD: {
            return {
                ...state,
                hasWeakPassword: payload,
            };
        }
        case AdminActionTypes.SET_APP_CLIENTS: {
            return {
                ...state,
                appClients: payload,
            };
        }
        case AdminActionTypes.SET_NOTIFICATION: {
            return {
                ...state,
                notification: payload,
            };
        }
        case AdminActionTypes.SET_ADMIN_CLIENT: {
            return {
                ...state,
                adminClient: payload,
            };
        }
        case AdminActionTypes.SET_USER: {
            return {
                ...state,
                user: payload,
            };
        }
        case AdminActionTypes.SET_CLIENT_FILTER: {
            return {
                ...state,
                clientListFilter: payload,
            };
        }
        case AdminActionTypes.SET_START_DATE_FILTER: {
            return {
                ...state,
                startDateFilter: payload,
            };
        }
        case AdminActionTypes.SET_END_DATE_FILTER: {
            return {
                ...state,
                endDateFilter: payload,
            };
        }
        case AdminActionTypes.SHOW_PDF_DIALOG: {
            return {
                ...state,
                showPdfFrameworkDialog: payload,
            };
        }
        case AdminActionTypes.SET_CURRENT_DECISION_TREE: {
            return {
                ...state,
                currentDecisionTree: payload,
            };
        }
        case AdminActionTypes.SET_CURRENT_REGION: {
            return {
                ...state,
                currentRegion: payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default adminReducer;
