/**@flow */
import React, { useState, useEffect } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { setNotification } from '../../redux/admin/adminActions';

/* type Notification = {
    type: 'error' | 'success' | 'info',
    content: string
}

type Props = {
    notification: ?Notification
}; */

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles = makeStyles((theme) => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const NotificationBar = (props) => {
    const { notification } = props;
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const variant = notification ? notification.type : 'info';
    const message = notification ? notification.content : '';
    const stayOpen = notification ? notification.stayOpen : false;
    const Icon = variantIcon[variant];
    const defaultAutoHideDuration = 6000;

    const dispatch = useDispatch();

    const _handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setNotification(null));
        setOpen(false);
    };

    useEffect(() => {
        if (notification !== null) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [notification]);

    return (
        <Snackbar
            open={open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            {...(!stayOpen && { autoHideDuration: defaultAutoHideDuration })}
            onClose={_handleClose}
        >
            <SnackbarContent
                className={classNames(classes[variant])}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={classNames(
                                classes.icon,
                                classes.iconVariant,
                            )}
                        />
                        {Array.isArray(message) ? (
                            <ul>
                                {message.map((line, index) => (
                                    //s eslint-disable-next-line react/no-array-index-key
                                    <li key={`message_line_${index}`}>
                                        {line}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            message
                        )}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        onClick={_handleClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                onClose={_handleClose}
            />
        </Snackbar>
    );
};

export default NotificationBar;
